import React from 'react'
import TableRow from 'semantic-ui-react/dist/es/collections/Table/TableRow'
import TableCell from 'semantic-ui-react/dist/es/collections/Table/TableCell'
import Placeholder from 'semantic-ui-react/dist/es/elements/Placeholder/Placeholder'
import PlaceholderLine from 'semantic-ui-react/dist/es/elements/Placeholder/PlaceholderLine'
import PlaceholderParagraph from 'semantic-ui-react/dist/es/elements/Placeholder/PlaceholderParagraph'

const KooperTablePlaceholder = ({ columns = 5, rows = 5, lines = 2 }) => {
  return Array.from({ length: rows }, (_, rowIndex) => {
    return (
      <TableRow key={`row-${rowIndex}`}>
        {Array.from({ length: columns }, (_, columnIndex) => (
          <TableCell key={`cell-${rowIndex}-${columnIndex}`}>
            <Placeholder>
              <PlaceholderParagraph>
                {Array.from({ length: lines }, (_, lineIndex) => (
                  <PlaceholderLine
                    key={`line-${rowIndex}-${columnIndex}-${lineIndex}`}
                  />
                ))}
              </PlaceholderParagraph>
            </Placeholder>
          </TableCell>
        ))}
      </TableRow>
    )
  })
}

export default KooperTablePlaceholder
