import React from 'react'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './toast.css'

const KooperToastContainer = (props) => {
  return <ToastContainer {...props} />
}

export default KooperToastContainer
