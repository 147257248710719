import SunEditor from 'suneditor-react'
import React from 'react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File

const options = {
  buttonList: [
    [
      'font',
      'fontSize',
      'bold',
      'underline',
      'italic',
      'strike',
      'hiliteColor',
      'textStyle',
      'fontColor',
      'indent',
      'align',
      'image',
      'video'
    ]
  ],
  font: [
    'Roboto',
    'Arial',
    'Times New Roman',
    'Comic Sans MS',
    'Courier New',
    'Impact',
    'Georgia',
    'tahoma'
  ]
}

const KooperEditorSmall = (props) => {
  const { setOptions = options, ...rest } = props
  return <SunEditor {...rest} setOptions={setOptions} />
}

export default KooperEditorSmall
