import React, { forwardRef } from 'react'
import Form from 'semantic-ui-react/dist/es/collections/Form/Form'

const KooperForm = forwardRef((props, ref) => {
  return (
    <Form ref={ref} {...props}>
      {props.children}
    </Form>
  )
})

export default KooperForm
