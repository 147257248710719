import React from 'react'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'

import Moment from 'moment'
Moment.locale('en')
momentLocalizer()

const KooperDatePicker = (props) => {
  return <DateTimePicker {...props} />
}
export default KooperDatePicker
