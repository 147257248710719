import React from 'react'
import Popup from 'semantic-ui-react/dist/es/modules/Popup/Popup'

const KooperTooltip = (props) => {
  return (
    <Popup inverted {...props}>
      {props.children}
    </Popup>
  )
}
export default KooperTooltip
