import React, { useMemo, forwardRef } from 'react'
import FormInput from 'semantic-ui-react/dist/es/collections/Form/FormInput'

const KooperFormInput = forwardRef((props, ref) => {
  const { value = '' } = props

  const valueLength = useMemo(() => {
    return value?.length ?? 0
  }, [value])

  const characterCount = useMemo(() => {
    return `${valueLength}/${props?.maxLength}`
  }, [valueLength, props?.maxLength])

  return (
    <React.Fragment>
      <FormInput {...props} ref={ref}>
        {props.children}
      </FormInput>
      {props?.maxLength && <div className='input-cnt'>{characterCount}</div>}
    </React.Fragment>
  )
})
export default KooperFormInput
