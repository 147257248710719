import React from 'react'
import PlaceholderParagraph from 'semantic-ui-react/dist/es/elements/Placeholder/PlaceholderParagraph'

const KooperPlaceholderParagraph = (props) => {
  return (
    <PlaceholderParagraph {...props}>{props.children}</PlaceholderParagraph>
  )
}

export default KooperPlaceholderParagraph
