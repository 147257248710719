import React, { useMemo } from 'react'
import FormTextArea from 'semantic-ui-react/dist/es/collections/Form/FormTextArea'

const KooperFormTextArea = (props) => {
  const { value = '' } = props

  const valueLength = useMemo(() => {
    return value?.length ?? 0
  }, [value])

  const characterCount = useMemo(() => {
    return `${valueLength}/${props?.maxLength}`
  }, [valueLength, props?.maxLength])

  return (
    <React.Fragment>
      <FormTextArea {...props}>{props.children}</FormTextArea>
      {props?.maxLength && <div className='input-cnt'>{characterCount}</div>}
    </React.Fragment>
  )
}

export default KooperFormTextArea
